import { useState, useEffect, useCallback } from 'react';
import userManager from '../../config/AuthConfig';
import { fetchPublicKey } from '../../api/KeyRequest';
import useCustomerIpBlocked from '../../api/IpValidation';
import { usePublicKeyDPContext } from '../../provider/LoginProvider';

const useUserAccess = (navigate) => {
  const [userAccess, setUserAccess] = useState(null);
  const { setPublicKey, publicKey } = usePublicKeyDPContext();

  const fetchPublicKeyData = useCallback(async () => {
    const dinersUrl = process.env.REACT_APP_DINERS_URL;
    const key = await fetchPublicKey(dinersUrl);
    setPublicKey(key);
  }, [setPublicKey]);

  const initializeUser = useCallback(async () => {
    try {
      const user = await userManager.getUser();
      if(!user || !user.profile){
        await userManager.removeUser();
        await userManager.signinRedirect();
        return;
      }
      const newUser = {
        userName: user.profile.preferred_username
      };
      setUserAccess(newUser);
      await fetchPublicKeyData();
    } catch (err) {
      console.error(err);
      navigate('/');
    }
  }, [fetchPublicKeyData, navigate]);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  const { isBlocked, error } = useCustomerIpBlocked(publicKey);

  return { userAccess, isBlocked, error };
};

export default useUserAccess;
