import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';
import { generateLocalKeyPair, encrypAES_ECB, rsaEncryptText, getAesKey, publicKeyToPem } from '../util/CryptoManagement';

export const getSingleSelectCustomer = async (user, publicKey, deviceInfo, dinersUrl) => {
  const headers = generateHeaders();
  let response = null;

  try {
    const keyPair = generateLocalKeyPair();
    const aesKey = getAesKey();
    const encryptedAesKey = rsaEncryptText(publicKey, aesKey);

    const encryptedUsername = encrypAES_ECB(user.userName, aesKey);

    headers['llave_simetrica'] = encryptedAesKey;
    headers['portal'] = `PBN`;

    const dataSingleSelectCustomer = {
      customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { systemUser: { userName: encryptedUsername } }),
      device: getRestObject(REST_OBJECT_TYPE.DEVICE, { sDeviceId: deviceInfo }),
      keyRSA: publicKeyToPem(keyPair.publicKey)
    };

    console.log("request SingleSelectCustomer", dataSingleSelectCustomer);
    response = await postEncryptRequest(`${dinersUrl}/users/singleSelectCustomerBasicData/`, publicKey, dataSingleSelectCustomer, headers, keyPair);
    console.log("response SingleSelectCustomer", response);
  } catch (error) {
    console.error(error);
    throw error;
  }
  return response;
};

export const closeSession = async (user, publicKey, dinersUrl) => {
  const headers = generateHeaders();
  let response = null;

  try {
    const data = {
      customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { systemUser: { userName: user.userName } })
    };

    console.log("request UserOut", data);
    response = await postEncryptRequest(`${dinersUrl}/perfilamiento/userOut/`, publicKey, data, headers);
    console.log("response UserOut", response);
  } catch (error) {
    console.error(error);
    throw error;
  }
  return response;
};

export const userExtract = () => {
  return null;
}