import { useState, useEffect } from 'react';
import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';

const useCustomerIpBlocked = (publicKey) => {
    const [isBlocked, setIsBlocked] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkCustomerIpBlocked = async () => {
            if (publicKey) {
                const dinersUrl = process.env.REACT_APP_DINERS_URL;
                const headers = generateHeaders();
                const data = {
                    customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { systemUser: { userName: null } })
                };

                try {
                    console.log("verifyCustomerIpBlocked request", data);
                    const response = await postEncryptRequest(`${dinersUrl}/directusers/verifyCustomerIpBlocked/`, publicKey, data, headers);
                    console.log("verifyCustomerIpBlocked response", response);
                    setIsBlocked(response?.dataExecutionTransaction?.executionTransactionStatus?.shortDesc !== 0);
                } catch (error) {
                    console.error('Error fetching customer IP block status', error);
                    setError(error);
                }
            }
        };

        checkCustomerIpBlocked();
    }, [publicKey]);

    return { isBlocked, error };
};

export default useCustomerIpBlocked;
