import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthCheck from './components/sesion/AuthCheck';
import Callback from './components/sesion/Callback';
import HomePage from './pages/home/HomePage';
import { LoginProvider } from './provider/LoginProvider';
import './styles/App.css';
import backgroundImage from './assets/images/background.jpg';

const App = () => {
  const divStyle = {
    backgroundImage: `url(${backgroundImage})`
  };

  return (
    <div className="app-container" style={divStyle}>
      <Router>
        <Routes>
          <Route path="/callback" element={<Callback />} />
          <Route
            path="/"
            element={
              <AuthCheck>
                <LoginProvider>
                  <HomePage />
                </LoginProvider>
              </AuthCheck>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

