// src/hooks/useHomePage.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../useDeviceInfo';
import useUserAccess from '../sesion/useUserAccess';
import useHomePageState from './useHomePageState';
import useHomePageAuth from './useHomePageAuth';
import useHomePageUserData from './useHomePageUserData';

const useHomePage = () => {
  const navigate = useNavigate();
  const deviceInfo = useDeviceInfo();
  const { userAccess, isBlocked, error } = useUserAccess(navigate);

  const {
    isOtpModalOpen,
    isLoading,
    alertDataModal,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  } = useHomePageState();

  const { handleLogout } = useHomePageAuth(setIsLoading);

  const homePageContext = {
    deviceInfo,
    userAccess,
    setIsLoading,
    setIsOtpModalOpen,
    showAlert
  };

  const { updateUserData } = useHomePageUserData(homePageContext);

  useEffect(() => {
    if (error !== undefined && error !== null) {
      showAlert("error.unknown.title", "error.unknown.message");
    }
  }, [error, showAlert]);

  useEffect(() => {
    if (isBlocked) {
      showAlert("error.ip-blocked.title", "error.ip-blocked.message");
    }
  }, [isBlocked, showAlert]);

  useEffect(() => {
    if (userAccess && isBlocked !== undefined && !isBlocked) {
      updateUserData();
    }
  }, [updateUserData, userAccess, isBlocked]);

  return {
    isOtpModalOpen,
    isLoading,
    alertDataModal,
    isBlocked,
    handleLogout,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  };
};

export default useHomePage;