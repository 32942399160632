import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../../config/AuthConfig';

const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await userManager.getUser();
        if (!user) {
          userManager.signinRedirect();
          return;
        }

        const now = Math.floor(Date.now() / 1000);
        if (user.expires_at < now) {
          try {
            await userManager.signinSilent();
            const renewedUser = await userManager.getUser();
            if (!renewedUser || renewedUser.expires_at < now) {
              userManager.signinRedirect();
            }
          } catch (err) {
            console.error("Silent renew failed:", err);
            userManager.signinRedirect();
          }
        }
      } catch (err) {
        console.error(err);
        userManager.signinRedirect();
      }
    };

    checkAuth();
  }, [navigate]);
};

export default useAuthCheck;
