import { useCallback } from 'react';
import { closeSession } from '../../api/UserRequest';
import userManager from '../../config/AuthConfig';
import { useUserContext, usePublicKeyDPContext } from '../../provider/LoginProvider';

const useHomePageAuth = (setIsLoading) => {
  const { user } = useUserContext();
  const { publicKey } = usePublicKeyDPContext();

  const handleLogout = useCallback(async () => {
    setIsLoading(true);
    try {
      if (user) {
        const dinersUrl = process.env.REACT_APP_DINERS_URL;
        await closeSession(user, publicKey, dinersUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      userManager.revokeAccessToken();
      userManager.removeUser();
      userManager.signinRedirect();
    }
  }, [user, publicKey, setIsLoading]);

  return { handleLogout };
};

export default useHomePageAuth;