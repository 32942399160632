import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useHomePageState = () => {
  const { t } = useTranslation("global");
  const [state, setState] = useState({
    isOtpModalOpen: false,
    isLoading: true,
    alertDataModal: null
  });

  const showAlert = useCallback((titleLangKey, messageLangKey) => {
    setState(prev => ({
      ...prev,
      alertDataModal: { title: t(titleLangKey), message: t(messageLangKey) },
      isLoading: false
    }));
  }, [t]);

  const setIsOtpModalOpen = useCallback((isOpen) => {
    setState(prev => ({ ...prev, isOtpModalOpen: isOpen }));
  }, []);

  const setIsLoading = useCallback((isLoading) => {
    setState(prev => ({ ...prev, isLoading }));
  }, []);


  return {
    ...state,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  };
};

export default useHomePageState;