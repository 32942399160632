import userManager from './AuthConfig';

class AuthService {
    async getUser() {
        return await userManager.getUser();
    }

    async renewToken() {
        try {
            const user = await userManager.signinSilent();
            if (user) {
                return {
                    userName: user.profile.preferred_username,
                    accessToken: user.access_token,
                    idToken: user.id_token
                };
            }
        } catch (error) {
            console.error('Error renewing token:', error);
            userManager.signinRedirect();
        }
    }
}

const authService = new AuthService();

export default authService;