import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';
import { generateRandomKey } from '../util/CryptoManagement';
import { closeSession } from './UserRequest';
import userManager from '../config/AuthConfig';

export const verifyAnalyticsAccess = async (user, dinersUrl, publicKey) => {
    const headers = generateHeaders();
    let response = null;

    const data = {
        establishment: getRestObject(REST_OBJECT_TYPE.ESTABLISHMENT, { ruc: user.establishment.ruc })
    };

    try {
        console.log(data);
        const responseBase = await postEncryptRequest(`${dinersUrl}/perfilamiento/verifyAnalyticsAccess/`, publicKey, data, headers);
        console.log("verifyAnalyticsAccess response with generic data:", responseBase);

        
        if (responseBase?.generic) {
            const generic = responseBase.generic;
            const key = generateRandomKey(16);
            const dataGeneric = {
                establishment: getRestObject(REST_OBJECT_TYPE.ESTABLISHMENT, { ruc: user.establishment.ruc }),
                generic: {
                    longDesc: `${user.establishment.ruc}|${user.establishment.businessName}|${Date.now()}|${generic.mySales}|${generic.myClients}|${generic.myIndustry}|${key}`
                }
            };
            console.log("verifyAnalyticsAccess request:", dataGeneric);
            response = await postEncryptRequest(`${dinersUrl}/perfilamiento/verifyAnalyticsAccess/`, publicKey, dataGeneric, headers);
            console.log("verifyAnalyticsAccess response:", response);
        }
    } catch (error) {
        console.error(error);
        throw error;
    }

    return response;
};

export const analyticsRedirect = async (user, dinersUrl, publicKey) => {
    const response = await verifyAnalyticsAccess(user, dinersUrl, publicKey);
    if (response.generic?.encryptedData) {
        try {
            userManager.revokeAccessToken();
            userManager.removeUser();
            await closeSession(user, publicKey, dinersUrl);
        } catch (error) {
            console.error(error);
        }

        const analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_URL;
        const redirectParams = {
            datos: response.generic.encryptedData,
            username: user.userName,
            origin: 'MERCHANTS'
        };

        const redirectUrl = createSecureRedirectUrl(analyticsBaseUrl, redirectParams);
        window.location.href = redirectUrl.toString();
    } else {
        throw Error("Redirection analiticacomercios error");
    }
}

function createSecureRedirectUrl(baseUrl, params) {
    const url = new URL(baseUrl);
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, value);
    });
    return url;
}