import React from 'react';
import useAuthCheck from '../../hooks/sesion/useAuthCheck';
import PropTypes from 'prop-types';

const AuthCheck = ({ children }) => {
  useAuthCheck();
  return <>{children}</>;
};

AuthCheck.propTypes = {
  children: PropTypes.node.isRequired
};
export default AuthCheck;
