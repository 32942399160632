import { generateLocalKeyPair, encryptRequest, decryptResponse } from '../util/CryptoManagement';
import axiosInstance from '../config/AxiosConfig';

export const REST_OBJECT_TYPE = {
  CUSTOMER: 'customer',
  DEVICE: 'device',
  OTP: 'otp',
  ESTABLISHMENT: 'establishment',
  PROFILE: 'profile'
};

export const DATA_MODEL_REST = {
  DINERS_FINANCIALS: 'diners.financials'
}

export const generateHeaders = () => {
  let headers = {
    'Accept': 'application/json',
    'Accept-Language': 'es-419,es;q=0.9',
    'Content-Type': 'application/json',
    'address': 'ip=127.0.0.1',
    'channel': 'IN',
    'feature_id': 'ROL@5500',
    'locale': 'ES',
    'portal': 'MER',
    'timestampCanal': new Date().toISOString()
  };
  return headers;
};


export const getRestObject = (objectType, data, dataModel = DATA_MODEL_REST.DINERS_FINANCIALS, version = '1.0') => {
  return {
    "@dataModel": dataModel,
    "@name": objectType,
    "@version": version,
    ...data
  };
}

export const postEncryptRequest = async (endpoint, publicKey, data = {}, headers = {}, keyPair = null) => {
  try {
    let localkeys = keyPair === null ? generateLocalKeyPair() : keyPair;
    const body = encryptRequest(data, publicKey, localkeys)
    const response = await axiosInstance.post(endpoint, body, { headers });
    const responseDecrypt = decryptResponse(response.data, localkeys.privateKey)
    return JSON.parse(responseDecrypt);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const postRequest = async (endpoint, data = {}, headers = {}) => {
  try {
    const response = await axiosInstance.post(endpoint, data, { headers });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}