// src/hooks/useHomePageUserData.js
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getSingleSelectCustomer } from '../../api/UserRequest';
import { analyticsRedirect } from '../../api/AnalyticsRequest';
import { getUserInfo } from '../../util/UserUtils';
import userManager from '../../config/AuthConfig';
import { useUserContext, usePublicKeyDPContext } from '../../provider/LoginProvider';

const useHomePageUserData = (context) => {
  const { publicKey } = usePublicKeyDPContext();
  const { setUser } = useUserContext();
  const {
    deviceInfo,
    userAccess,
    setIsLoading,
    setIsOtpModalOpen,
    showAlert
  } = context;

  const updateUserData = useCallback(async () => {
    if (!deviceInfo || !publicKey || !userAccess) return;

    try {
      setIsLoading(true);
      const dinersUrl = process.env.REACT_APP_DINERS_URL;
      const userData = await getSingleSelectCustomer(userAccess, publicKey, deviceInfo, dinersUrl);
      const userInfo = getUserInfo(userData, userAccess);

      if (userData.device?.active) {
        await analyticsRedirect(userInfo, dinersUrl, publicKey);
      }

      setUser(userInfo);
      setIsOtpModalOpen(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      error.response?.status === 400
        ? userManager.signinRedirect()
        : showAlert("error.unknown.title", "error.unknown.message");
    }
  }, [deviceInfo,
    publicKey,
    userAccess,
    setUser,
    setIsLoading,
    setIsOtpModalOpen,
    showAlert]);

  return { updateUserData };
};

useHomePageUserData.propTypes = {
  context: PropTypes.shape({
    deviceInfo: PropTypes.object,
    userAccess: PropTypes.object,
    setIsLoading: PropTypes.func.isRequired,
    setIsOtpModalOpen: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired
  }).isRequired
};

export default useHomePageUserData;