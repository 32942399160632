
export const validateUserData = (userData) => {
    if (!userData) {
      throw new Error('userData is null or undefined');
    }
  
    const { establishment, customer } = userData;
    if (!establishment || typeof establishment !== 'object' || !establishment.ruc || !establishment.businessName) {
      throw new Error('Invalid or incomplete establishment data');
    }
    if (!customer || typeof customer !== 'object' || !customer.customerId || !customer.userProfile || !customer.customerUserName
      || !customer.additionalCustomerType || (!customer.OTPMail && !customer.OTPCellphone)) {
      throw new Error('Invalid or incomplete customer data');
    }
  
    return true;
  };
  
  export const getUserInfo = (userData, userAccess) => {
    validateUserData(userData);
    return {
      ...userAccess,
      establishment: {
        ruc: `${userData.establishment.ruc}`,
        businessName: userData.establishment.businessName
      },
      otp: {
        mail: userData.customer.OTPMail,
        cellphone: userData.customer.OTPCellphone
      },
      customer: {
        customerId: userData.customer.customerId,
        userProfile: userData.customer.userProfile,
        userName: userData.customer.customerUserName,
        additionalCustomerType: userData.customer.additionalCustomerType
      }
    };
  };
  