import React from 'react';
import { useTranslation } from 'react-i18next';

const FooterBar = () => {
    const { t } = useTranslation("global");
    const moreInformationUrl = process.env.REACT_APP_MORE_INFORMATION;

    const handleButtonClick = () => {
        window.open(moreInformationUrl, '_blank');
    };

    return (
        <div className="footerBar">
            <p>
                <button
                    onClick={handleButtonClick}
                    id="buttonForget"
                    className="button button--type-link"
                    tabIndex="0"
                    type="button"
                >
                    <span>{t("footer-bar.more-information")}</span>
                </button>
                {t("footer-bar.copyright")}<br />
                {t("footer-bar.autor-rights")}
            </p>
        </div>
    );
};

export default FooterBar;
