import axios from 'axios';
import authService from './AuthService';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config) => {
        const user = await authService.getUser();
        if (user?.access_token && user?.profile) {
            config.headers['access_token'] = user.access_token;
            config.headers['id_token'] = user.id_token;
            config.headers['organization_operator_id'] = `userName=${user.profile.preferred_username};`;
        }
        return config;
    },
    (error) => Promise.reject(error instanceof Error ? error : new Error(String(error)))
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const user = await authService.renewToken();
            if (user) {
                originalRequest.headers['access_token'] = user.accessToken;
                originalRequest.headers['id_token'] = user.idToken;
                originalRequest.headers['organization_operator_id'] = `userName=${user.userName};`;
                return axiosInstance(originalRequest);
            }
        }
        return Promise.reject(error instanceof Error ? error : new Error(String(error)));
    }
);

export default axiosInstance;