import { useCallback, useEffect } from 'react';
import { sendOtpRequest } from '../../api/OTPRequest';
import { usePublicKeyDPContext, useUserContext } from '../../provider/LoginProvider';

const useOtpRequest = (showAlert) => {
  const { publicKey } = usePublicKeyDPContext();
  const { user } = useUserContext();

  const requestOtp = useCallback(() => {
    const fetchOtp = async () => {
      try {
        const dinersUrl = process.env.REACT_APP_DINERS_URL;
        await sendOtpRequest(user, dinersUrl, publicKey);
      } catch (error) {
        console.error(error);
        showAlert("error.unknown.title", "error.unknown.message");
      }
    };
    fetchOtp();
  }, [user, publicKey, showAlert]);

  useEffect(() => {
    requestOtp();
  }, [requestOtp]);

  return requestOtp;
};

export default useOtpRequest;
