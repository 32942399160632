import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../../config/AuthConfig';
import LoadingBounces from '../util/LoadingBounces';

const Callback = () => {
  const navigate = useNavigate();
  const [isHandled, setIsHandled] = useState(false);

  useEffect(() => {
    if (!isHandled) {
      setIsHandled(true);
      console.log("el userManager es: ", userManager);
      userManager.signinRedirectCallback().then(user => {
        console.log('User logged in', user);
        navigate('/');
      }).catch(err => { 
        console.error('Error during signinRedirectCallback:', err);
        window.location.replace(window.location.origin);
      });
    }
  }, [isHandled, navigate]);
  return (
    <div className="App">
      <div>
        <LoadingBounces />
      </div>
    </div>);
};

export default Callback;
